import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BASE_URL } from "constants";

const scanServices = createApi({
  reducerPath: "scanApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["scan"],
  endpoints: (builder) => ({
    getScanTokenInfo: builder.query({
      // query: (token) => `schedule/get?scanTicketToken=${token}`,
      query: (token) => `evnts/schedule/get?scanTicketToken=${token}`,
      providesTags: ["scan"],
    }),
    getBookingInfo: builder.query({
      query: (bookingId) => `bookings/getBookingDetails/${bookingId}`,
    }),
    checkInTickets: builder.mutation({
      query: ({ bookingId, ticketId }) => ({
        url: "bookings/checkinTickets",
        method: "POST",
        body: {
          bookingId: bookingId,
          ticketId: ticketId,
        },
      }),
      invalidatesTags: ["scan"],
    }),
    getScanTicketInfo: builder.query({
      query: ({ bookingId, eventScheduleId, ticketId }) => {
        const params = new URLSearchParams();

        if (bookingId) params.append("bookingId", bookingId);
        if (eventScheduleId) params.append("eventScheduleId", eventScheduleId);
        if (ticketId) params.append("ticketId", ticketId);

        return `bookings/getScanTicket?${params.toString()}`;
      },
    }),
    getScanAddonInfo: builder.query({
      query: ({ bookingId, eventScheduleId, addonId }) => {
        const params = new URLSearchParams();

        if (bookingId) params.append("bookingId", bookingId);
        if (eventScheduleId) params.append("eventScheduleId", eventScheduleId);
        if (addonId) params.append("addonId", addonId);

        return `bookings/getScanAddon?${params.toString()}`;
      },
    }),
    redeemAddon: builder.mutation({
      query: ({ bookingId, addonId }) => ({
        url: "bookings/redeemAddons",
        method: "POST",
        body: {
          bookingId: bookingId,
          addonId: addonId,
        },
      }),
      invalidatesTags: ["scan"],
    }),
  }),
});

export const {
  useGetScanTokenInfoQuery,
  useLazyGetBookingInfoQuery,
  useCheckInTicketsMutation,
  useLazyGetScanTicketInfoQuery,
  useLazyGetScanAddonInfoQuery,
  useRedeemAddonMutation,
} = scanServices;

export default scanServices;
