import React from "react";

function ArrowLeftIcon({ color, w, h }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w ? w : "100%"}
      height={h ? h : "100%"}
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M23.334 14.3203C23.334 14.7633 23.0048 15.1294 22.5777 15.1873L22.459 15.1953L4.95898 15.1953C4.47573 15.1953 4.08398 14.8036 4.08398 14.3203C4.08398 13.8773 4.41316 13.5112 4.84025 13.4533L4.95898 13.4453L22.459 13.4453C22.9422 13.4453 23.334 13.8371 23.334 14.3203Z"
        fill={color ? color : "currentColor"}
      />
      <path
        d="M12.634 20.7286C12.9764 21.0696 12.9776 21.6236 12.6367 21.9661C12.3267 22.2774 11.8406 22.3067 11.4975 22.0532L11.3992 21.9687L4.34088 14.9407C4.02864 14.6298 4.00024 14.142 4.25568 13.7989L4.34083 13.7007L11.3992 6.67151C11.7416 6.33051 12.2956 6.33165 12.6366 6.67407C12.9466 6.98535 12.9738 7.47152 12.719 7.81354L12.634 7.9115L6.19867 14.321L12.634 20.7286Z"
        fill={color ? color : "currentColor"}
      />
    </svg>
  );
}

export default ArrowLeftIcon;
