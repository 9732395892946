import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { Button, Flex, Text } from "@mantine/core";
import { useAtom } from "jotai";

import createPaymentIntent from "utils/createPaymentIntent";

import { ErrorAlert, Loader, UserDetails } from "components";
import iDEALIcon from "../../assets/icon/iDEAL.png";

import { BOOK_SERVICE_BODY, BOOKING_ID, STRIPE_AC_ID } from "constants";
import { ROUTE_NAMES } from "routes/names";
import { ChevronsIconLeft } from "assets/icon";
import EventInfoCard from "views/TicketBooking/EventInfoCard";
import EventBuyingSummary from "views/TicketBooking/EventBuyingSummary";
import { step } from "store/atoms";

export default function IdealPayment() {
  const location = useLocation();
  const navigate = useNavigate();
  const reduxstate = useSelector((state) => state.State);

  const stripeAcId = window.localStorage.getItem(STRIPE_AC_ID);
  let bookServiceBody = window.localStorage.getItem(BOOK_SERVICE_BODY) || {};
  bookServiceBody = Object.keys(bookServiceBody).length
    ? JSON.parse(bookServiceBody)
    : {};

  const { idealBank } = location.state;

  const [stripe, setStripe] = useState(null);
  const [, setEventStep] = useAtom(step);
  const [processing, setProcessing] = useState(false);

  async function getClientSecret() {
    const body = {};

    if (reduxstate.currentDataFor === "service") {
      body.serviceId = bookServiceBody.service_id;
      body.slotDuration = bookServiceBody.duration;
      body.priceType = bookServiceBody.priceType;
    } else {
      // body.eventScheduleId = reduxstate.id;
      body.scheduleId = reduxstate.id;
      const selectedEventPricing = reduxstate.selectedEventPricing
        .filter((item) => item.bookingQty && item.bookingQty > 0)
        .map(({ bookingQty, totalPrice, ticketType }) => ({
          bookingQty,
          totalPrice,
          ticketType,
        }));

      body.selectedEventPricing = selectedEventPricing;

      const selectedAddonsPricing =
        reduxstate.addonsPricingList.length > 0
          ? reduxstate.addonsPricingList
              .filter((addon) => "bookingQty" in addon && addon.bookingQty > 0)
              .map((addon) => ({
                bookingQty: addon.bookingQty,
                totalPrice: addon.totalPrice,
                id: addon.id,
              }))
          : [];

      body.selectedAddonsPricing = selectedAddonsPricing;
    }

    console.log("TOTALPRICE", reduxstate.totalPrice);
    const res = await createPaymentIntent({
      amount: Number(reduxstate.totalPrice),
      body,
      paymentMethod: "ideal",
    });
    return res;
  }

  useEffect(() => {
    setProcessing(true);
    loadStripe(
      process.env.REACT_APP_ENVIRONMENT === "PROD"
        ? process.env.REACT_APP_STRIPE_LIVE_KEY
        : process.env.REACT_APP_STRIPE_TEST_KEY,
      stripeAcId ? { stripeAccount: stripeAcId } : undefined
    ).then((stripeRes) => {
      setStripe(stripeRes);
    });
    setProcessing(false);
  }, [stripeAcId]);

  const handlePay = async () => {
    setProcessing(true);
    const { client_secret, referenceId } = await getClientSecret();

    window.localStorage.setItem(BOOKING_ID, referenceId);

    let return_url = process.env.REACT_APP_STRIPE_RETURN_URL_DEV;

    switch (true) {
      case process.env.REACT_APP_ENVIRONMENT === "PRE_STAGING":
        return_url = process.env.REACT_APP_STRIPE_RETURN_URL_PRE_STAGING;
        break;
      case process.env.REACT_APP_ENVIRONMENT === "STAGING":
        return_url = process.env.REACT_APP_STRIPE_RETURN_URL_STAGING;
        break;
      case process.env.REACT_APP_ENVIRONMENT === "PROD":
        return_url = process.env.REACT_APP_STRIPE_RETURN_URL_PROD;
        break;
      default:
        break;
    }

    const { error, paymentIntent } = await stripe.confirmIdealPayment(
      client_secret,
      {
        payment_method: {
          ideal: {
            bank: idealBank,
          },
        },
        return_url: `${return_url}${ROUTE_NAMES.PAYMENT_CHECK}`,
      }
    );

    if (paymentIntent) {
      if (paymentIntent.status === "succeeded") {
        navigate(ROUTE_NAMES.PAYMENT_COMPLETE, {
          state: { paymentSuccess: true },
        });
      }
    }

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    if (error.type === "card_error" || error.type === "validation_error") {
      confirmAlert({
        customUI: ({ onClose }) =>
          ErrorAlert({
            onClose: () => {
              onClose();
              navigate(-1);
            },
            error: error.message,
            serverError: false,
          }),
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) =>
          ErrorAlert({
            onClose: () => {
              onClose();
              navigate(-1);
            },
            error: "An unexpected error occurred.",
            serverError: false,
          }),
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
    setProcessing(false);
  };

  const handleChangePaymentMethod = () => {
    setEventStep(3);
    navigate(-1, { replace: true });
  };

  if (!stripe) {
    return (
      <div className="ticket-view-loading">
        <Loader />
      </div>
    );
  }

  return (
    <div className="ticket-services">
      <div className="wrapper">
        <div className="page-hdr">
          <button className="back-btn" onClick={handleChangePaymentMethod}>
            <ChevronsIconLeft />
            <div className="lbl">Back</div>
          </button>
          <div className="title">Review & Pay</div>
        </div>
        <div className="content">
          <Flex direction="column">
            <EventInfoCard
              reduxstate={reduxstate}
              selectedStep={5}
              isReadMore={false}
              toggleReadMore={() => {}}
            />
            <UserDetails />
            <EventBuyingSummary reduxstate={reduxstate} />
            <div className="section">
              <div className="block">
                <Flex direction="row" justify="space-between" align="center">
                  <Flex direction="row" gap="sm" align="center">
                    <img
                      src={iDEALIcon}
                      alt="iDEAL icon"
                      style={{ width: 36, height: 36 }}
                    />
                    <Text fw={700} fz="lg">
                      iDEAL Pay
                    </Text>
                  </Flex>
                  <Button
                    variant="subtle"
                    onClick={handleChangePaymentMethod}
                    fz="md"
                    c="#584CF4"
                  >
                    Change
                  </Button>
                </Flex>
              </div>
            </div>
            <Button
              fullWidth
              radius={10}
              bg="#f64e60"
              size="lg"
              fz="md"
              sx={{
                "&:hover": {
                  backgroundColor: "#f64e60",
                  opacity: 0.7,
                  transition: "all 0.3s ease-in-out", // Added transition property
                },
              }}
              onClick={handlePay}
              loading={processing}
            >
              Pay & Confirm booking
            </Button>
          </Flex>
        </div>
      </div>
    </div>
  );
}
