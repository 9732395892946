import React from "react";

const ErrorAlert = ({ onClose, error, serverError, showBtn = true }) => {
  return (
    <div className="error-alert">
      <h1 className="title">{serverError ? "Something went wrong!" : ""}</h1>
      <p className="description">{error}</p>
      {showBtn && (
        <button className="action" onClick={onClose}>
          Ok
        </button>
      )}
    </div>
  );
};

export default ErrorAlert;
