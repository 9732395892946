import moment from "moment";
import { rem, Flex, Image, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import { LocationIcon, TimerIcon } from "assets/icon";

function ServiceInfoCard({
  serviceData,
  isReadMore,
  toggleReadMore,
  selectedStep,
  when,
  where,
}) {
  const isMobile = useMediaQuery("(max-width: 425px)");

  return (
    <div className="section">
      <div className="block info">
        <Flex direction="row" gap="md">
          <Image
            src={serviceData?.servicePhoto}
            height={rem(90)}
            width={rem(130)}
            radius="md"
          />
          <Flex direction="column" gap={rem(4)}>
            <Text size={isMobile ? "lg" : "xl"} weight={600}>
              {serviceData?.serviceName}
            </Text>
            <Flex direction="row" gap="sm" align="center">
              <Flex direction="column" gap={rem(5)}>
                <Flex direction="row" align="center" gap="xs">
                  <Image
                    src={serviceData?.teacher_detail?.profilePic}
                    height={rem(40)}
                    width={rem(40)}
                    radius="xl"
                  />
                  <Flex direction="column">
                    <Text color="dark" size={rem(13)} weight={600}>
                      Provided by
                    </Text>
                    <Text color="dark.3" size={isMobile ? rem(10) : rem(14)}>
                      {serviceData?.teacher_detail?.fullName}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        {selectedStep > 1 && (
          <Flex mt="xl" direction="column">
            <div className="item">
              <div className="icon">
                <LocationIcon />
              </div>
              <div className="meta">
                <div className="lbl">Where</div>
                <div className="value">{where?.venueName}</div>
                <div className="text">{where?.address?.address}</div>
              </div>
            </div>
            <div className="item">
              <div className="icon">
                <TimerIcon />
              </div>
              <div className="meta">
                <div className="lbl">When</div>
                <div className="value">
                  {moment(when?.day).format("dddd, MMMM D")} at {when?.start} -{" "}
                  {when?.end}
                </div>
              </div>
            </div>
          </Flex>
        )}
        {selectedStep <= 1 && (
          <div className="about-blk">
            <div className="label">Description</div>
            <div className="description">
              {isReadMore
                ? `${serviceData?.description.substring(0, 240)}...`
                : serviceData?.description}
              {""}
              <span onClick={toggleReadMore} className="read-or-hide">
                {isReadMore ? " Read more" : " Show less"}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ServiceInfoCard;
