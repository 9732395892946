import React from "react";

function ChevronsIconRight({ color, w, h }) {
  return (
    <svg
      width={w ? w : "100%"}
      height={h ? h : "100%"}
      fill="none"
      stroke={color ? color : "currentColor"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m9 18 6-6-6-6"></path>
    </svg>
  );
}

export default ChevronsIconRight;
