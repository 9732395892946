import { useMemo } from "react";
import { Flex, Text } from "@mantine/core";

export default function UserDetails() {
  const fullName = window.localStorage.getItem("@user-fullName");
  const username = window.localStorage.getItem("@user-username");
  const email = window.localStorage.getItem("@user-email");

  const renderUserEmail = useMemo(() => {
    if (!email) {
      return "Not Found";
    }

    if (email.length > 20) {
      if (email.includes("@") && email.includes(".")) {
        const emailSplit1 = email.split("@");
        const emailSplit2 = emailSplit1[1].split(".");
        let hostname = emailSplit2[0];

        if (hostname.length > 2) {
          hostname = `${hostname.substring(0, 2)}..`;
          emailSplit2[0] = hostname;
        } else {
          return email;
        }

        const hostAndDomain = emailSplit2.join(".");
        const emailTruncated = emailSplit1[0] + "@" + hostAndDomain;

        return emailTruncated;
      }
      return email;
    }

    return email;
  }, [email]);

  return (
    <div className="section">
      <div className="block">
        <Flex direction="row" justify="center" align="center">
          <Text c="#f64e60" fz="lg" fw={700}>
            Your Details
          </Text>
        </Flex>
        <Flex direction="column" gap="xs">
          <Flex direction="row" justify="space-between" align="center">
            <Text c="#616161" fw={700}>Full Name</Text>
            <Text fz="sm">{fullName}</Text>
          </Flex>
          <Flex direction="row" justify="space-between" align="center">
            <Text c="#616161" fw={700}>Phone Number</Text>
            <Text fz="sm">{username.startsWith("+") ? username : `+${username}`}</Text>
          </Flex>
          <Flex direction="row" justify="space-between" align="center">
            <Text c="#616161" fw={700}>Email</Text>
            <Text fz="sm">{renderUserEmail}</Text>
          </Flex>
        </Flex>
      </div>
    </div>
  );
}
