import { configureStore } from "@reduxjs/toolkit";

import ticketServices from "./services/ticketServices";
import stateReducer from "./reducer/index";
import scanServices from "./services/scanServices";

const Store = configureStore({
  reducer: {
    [ticketServices.reducerPath]: ticketServices.reducer,
    [scanServices.reducerPath]: scanServices.reducer,
    State: stateReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      ticketServices.middleware,
      scanServices.middleware
    ),
});

export default Store;
