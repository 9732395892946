import React from "react";

function ClockIcon({ color, w, h }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w ? w : "100%"}
      height={h ? h : "100%"}
      fill="none"
      viewBox="0 0 22 24"
    >
      <path
        fill={color ? color : "currentColor"}
        d="M10.5 23.375c-5.339 0-9.667-4.328-9.667-9.667a9.667 9.667 0 1119.334 0 9.678 9.678 0 01-9.667 9.667zm0-16.917a7.25 7.25 0 107.25 7.25 7.259 7.259 0 00-7.25-7.25zm1.209 8.458H9.292V8.875h2.417v6.041zm7.604-7.604l-2.417-2.416 1.709-1.709 2.416 2.417-1.707 1.707-.002.001zm-5.188-4.479h-7.25V.417h7.25v2.416z"
      ></path>
    </svg>
  );
}

export default ClockIcon;
