import { API_URL } from "constants";

/**
 * Creats a new PaymentIntent / Fetches a new client_secret
 * @param {number} amount - The amount for the PaymentIntent.
 * @param {object} body - The body for the ticket.
 * @param {string} paymentMethod - The payment method for the PaymentIntent.
 * @returns {Promise<object>} - The PaymentIntent. Access the client_secret with `PaymentIntent.client_secret`.
 */
export default async function createPaymentIntent({
  amount,
  body,
  paymentMethod,
}) {
  const res = await fetch(API_URL + "/bookings/payments/createStripePaymentIntentUA", {
    method: "POST",
    body: JSON.stringify({
      amount,
      currency: "eur",
      isPackage: false,
      paymentMethod,
      ...body,
    }),
  });

  const resJson = await res.json();

  return resJson.statusCode === 200
    ? resJson.data
    : { client_secret: "", referenceId: "" };
}
