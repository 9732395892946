import React from "react";
import Loader from "./Loader";

function Splash() {
  return (
    <div className="splash-screen">
       <Loader size={40} />
      {/* <img src="/images/logo.svg" className="logo" /> */}
    </div>
  );
}

export default Splash;
