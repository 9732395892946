import React from "react";

function CardIcon({ w, h, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w ? w : "100%"}
      height={h ? h : "100%"}
      fill="none"
      viewBox="0 0 48 48"
    >
      <path
        fill={color ? color : "#000"}
        fillRule="evenodd"
        d="M2 13a4 4 0 014-4h36a4 4 0 014 4v22a4 4 0 01-4 4H6a4 4 0 01-4-4V13zm2 2h40v6H4v-6zm3 14a1 1 0 00-1 1v4a1 1 0 001 1h8a1 1 0 001-1v-4a1 1 0 00-1-1H7z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CardIcon;
