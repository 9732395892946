import React from "react";

function TikkieIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#413F80"
        d="M0 26.598v.001c.022.777.029 1.559.163 2.33.143.818.347 1.615.737 2.356a7.556 7.556 0 002.165 2.543 7.106 7.106 0 002.166 1.14c1.157.363 2.35.502 3.561.509.756.005 1.51.023 2.267.02 5.488-.023 10.976.038 16.464-.033.729-.01 1.445-.096 2.16-.223a7.407 7.407 0 003.616-1.71c1.208-1.045 2.022-2.325 2.39-3.87.24-1.01.3-2.035.311-3.063v-.206c0-.079-.031-17.47-.034-17.7a10.412 10.412 0 00-.178-1.88c-.148-.757-.357-1.495-.73-2.18a7.585 7.585 0 00-1.5-1.943 7.486 7.486 0 00-3.27-1.813c-1.136-.294-2.293-.36-3.46-.359A.033.033 0 0126.822.5H9.172a.1.1 0 01-.002.017C8.51.53 7.846.524 7.187.604c-.721.087-1.436.218-2.118.463A7.477 7.477 0 002.26 2.859C1.357 3.769.719 4.822.38 6.048.079 7.142.013 8.258.011 9.381"
      ></path>
      <path
        fill="url(#paint0_linear_18_940)"
        fillRule="evenodd"
        d="M35.991 17.777l-9.532-9.233L6.602 29.482l-2.436.652 4.618 5.343h.021c.647.004 1.293.018 1.94.02l.937-.002a813.5 813.5 0 011.482-.003l14.273-.029.03.003.126-.002c.73-.01 1.45-.096 2.166-.222a7.44 7.44 0 003.628-1.71c2.526-2.204 2.6-5.144 2.603-5.616l.001-10.14z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#53BEB8"
        fillRule="evenodd"
        d="M18.223 5.643c-7.011 0-12.675 5.522-12.675 12.334 0 2.287.636 4.425 1.778 6.261v.366c0 4.177-3.159 5.53-3.159 5.53s4.465.045 6.829-1.988h.002c.021 0 .048-.031.079-.031.03 0 .056.03.078.03h.003c2.02 1.48 4.45 2.126 7.065 2.126 7.012 0 12.697-5.503 12.697-12.315 0-6.811-5.685-12.313-12.698-12.313"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint1_linear_18_940)"
        fillRule="evenodd"
        d="M35.99 27.018v-.012l.007-1.226v-.204l-5.326-5.175-8.433-8.226-7.679 2.188-.713 7.907 13.608 13.192.214-.002a13.606 13.606 0 002.301-.276c1.293-.316 2.851-.995 4.133-2.456a7.077 7.077 0 001.585-3.068c.208-.873.281-1.755.304-2.642z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FAFDFA"
        fillRule="evenodd"
        d="M12.188 19.435c-.497 0-.736-.215-.736-.699 0-.412.239-.662.736-.662h.019v-.34c0-.198 0-.413.019-.627h-.038c-.497 0-.736-.216-.736-.7 0-.412.239-.662.736-.662h.24a6.129 6.129 0 011.586-2.83c1.236-1.272 2.784-1.9 4.648-1.9 1.088 0 2.046.216 2.857.664.83.43 1.235.949 1.254 1.54 0 .77-.498 1.468-1.217 1.486-.35.018-.756-.126-1.216-.394-.462-.286-1.015-.43-1.66-.43-1.052 0-1.881.359-2.49 1.057a3.77 3.77 0 00-.516.806h2.821c.461 0 .738.25.738.663 0 .483-.277.7-.738.7h-3.172c-.019.214-.037.43-.037.626v.34h2.14c.442 0 .72.251.72.663 0 .484-.277.7-.72.7h-1.844c.144.37.35.715.608 1.021.608.699 1.439 1.057 2.489 1.057.646 0 1.199-.144 1.66-.412.46-.287.866-.43 1.217-.412.719.018 1.217.734 1.217 1.469-.019.609-.424 1.128-1.254 1.576-.811.43-1.77.645-2.858.645-1.863 0-3.412-.627-4.647-1.899-.83-.86-1.364-1.88-1.64-3.045h-.186v-.001z"
        clipRule="evenodd"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_18_940"
          x1="17.065"
          x2="26.96"
          y1="20.441"
          y2="32.666"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.15"></stop>
          <stop offset="1" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_18_940"
          x1="18.275"
          x2="29.373"
          y1="16.173"
          y2="29.885"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.2"></stop>
          <stop offset="1" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default TikkieIcon;
