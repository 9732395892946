import React, { forwardRef } from "react";

import Loader from "./Loader";
import ErrorAlert from "./ErrorAlert";

export const PrivateCodeVerificationAlert = forwardRef(({ onVerify }, ref) => {
  return (
    <div className="error-alert">
      <div className="title">The Class is private</div>
      <div className="description">Please enter booking code to proceed</div>
      <div className="private-booking-field-control">
        <div className="field">
          <input className="input" ref={ref} />
        </div>
        <button className="button-primary" onClick={onVerify}>
          <span>Enter</span>
        </button>
      </div>
    </div>
  );
});

export const PrivateCodeConfirmingAlert = () => {
  return (
    <div className="error-alert">
      <div className="title">Verifying</div>
      <div className="loading">
        <Loader size={22} thin={2} />
      </div>
    </div>
  );
};

export const ValidPrivateCodeAlert = ({ onClose }) => {
  return (
    <div className="error-alert">
      <div className="title">Verified</div>
      <button className="action" onClick={onClose}>
        Ok
      </button>
    </div>
  );
};

export const InvalidPrivateCodeAlert = ({ onClose }) => {
  return <ErrorAlert onClose={onClose} error={"Invalid Code"} />;
};
