import React from "react";

function LocationIcon({ color, h, w }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w ? w : "100%"}
      height={h ? h : "100%"}
      fill="none"
      viewBox="0 0 16 20"
    >
      <path
        fill={color ? color : "currentColor"}
        d="M8 10c.55 0 1.021-.196 1.413-.588C9.804 9.021 10 8.55 10 8c0-.55-.196-1.021-.587-1.413A1.928 1.928 0 008 6c-.55 0-1.02.196-1.412.587A1.927 1.927 0 006 8c0 .55.196 1.02.588 1.412C6.979 9.804 7.45 10 8 10zm0 7.35c2.033-1.867 3.542-3.563 4.525-5.088C13.508 10.737 14 9.383 14 8.2c0-1.817-.58-3.304-1.738-4.463C11.104 2.579 9.683 2 8 2c-1.683 0-3.104.579-4.263 1.737C2.579 4.896 2 6.383 2 8.2c0 1.183.492 2.537 1.475 4.062.983 1.525 2.492 3.221 4.525 5.088zM8 20c-2.683-2.283-4.687-4.404-6.012-6.363C.663 11.679 0 9.867 0 8.2c0-2.5.804-4.492 2.413-5.975C4.021.742 5.883 0 8 0c2.117 0 3.979.742 5.587 2.225C15.196 3.708 16 5.7 16 8.2c0 1.667-.662 3.479-1.987 5.437C12.688 15.596 10.683 17.717 8 20z"
      ></path>
    </svg>
  );
}

export default LocationIcon;
