import React from "react";

function CalenderIcon({ color, h, w }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w ? w : "100%"}
      height={h ? h : "100%"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={color ? color : "currentColor"}
        strokeWidth="1.5"
        d="M18 4.75H6A3.25 3.25 0 002.75 8v10A3.25 3.25 0 006 21.25h12A3.25 3.25 0 0021.25 18V8A3.25 3.25 0 0018 4.75z"
      ></path>
      <path
        stroke={color ? color : "currentColor"}
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M6.75 2.75v3.5M3.75 9.25h16.5M17.75 2.75v3.5"
      ></path>
      <path
        fill={color ? color : "currentColor"}
        d="M8.25 14a.75.75 0 100-1.5.75.75 0 000 1.5zM12.25 14a.75.75 0 100-1.5.75.75 0 000 1.5zM16.25 14a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 18a.75.75 0 100-1.5.75.75 0 000 1.5zM12.25 18a.75.75 0 100-1.5.75.75 0 000 1.5zM16.25 18a.75.75 0 100-1.5.75.75 0 000 1.5z"
      ></path>
    </svg>
  );
}

export default CalenderIcon;
