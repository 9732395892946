import React, { useState, useEffect } from "react";
import { useParams, Navigate, useNavigate } from "react-router-dom";
import { Flex, Text } from "@mantine/core";

import { Loader } from "components";

import {
  addParticipantToEvent,
  checkTikkiePayStatus,
  sendConfirmationEmail,
} from "core/methods";
import {
  ADD_PARTICIPANT_BODY,
  BOOKING_ID,
  SEND_EMAIL_CONFIRMATION_DATA,
  TOTAL_PRICE,
} from "../../constants";

export default function TikkiePaymentVerification() {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [reduxState, setReduxState] = useState({});
  const [addParticipantresponse, setAddParticipantresponse] = useState({});
  const [, setTotalPrice] = useState(0);
  const [isBookingComplete, setIsBookingComplete] = useState(false);
  const [loadingText, setLoadingText] = useState("Confirming your payment...");

  const fetchPaymentStatus = async () => {
    const [isSuccess, data] = await checkTikkiePayStatus({
      paymentRequestToken: id,
    });

    if (isSuccess) {
      if (data?.numberOfPayments > 0) {
        setLoadingText("Adding your booking...");

        await handleAddParticipant().then(() => {
          const totalAmount = window.localStorage.getItem(TOTAL_PRICE);
          setTotalPrice(Number(totalAmount));
          setIsLoading(false);
        }).catch(() => {});
      } else {
        setTimeout(() => {
          fetchPaymentStatus();
        }, 2000);
      }
    }
  };

  const handleSendEmailConfirmation = async (apr) => {
    const sendEmailConfirmationData = window.localStorage.getItem(
      SEND_EMAIL_CONFIRMATION_DATA
    );
    const { ticketFields, eventDetails, reduxstate } = JSON.parse(
      sendEmailConfirmationData
    );
    setReduxState(reduxstate);
    const body = {
      ticketFields,
      eventDetails,
      addParticipantresponse: apr,
      reduxstate,
    };

    const [isSuccess] = await sendConfirmationEmail(body);

    if (isSuccess) {
      window.localStorage.removeItem(SEND_EMAIL_CONFIRMATION_DATA);
    } else {
      throw new Error();
    }
  };

  const handleAddParticipant = async () => {
    if (!isBookingComplete) {
      const addParticipantBody =
        window.localStorage.getItem(ADD_PARTICIPANT_BODY);

      const [isSuccess, data] = await addParticipantToEvent({
        ...JSON.parse(addParticipantBody),
        bookingId: window.localStorage.getItem(BOOKING_ID),
      });

      if (isSuccess) {
        await handleSendEmailConfirmation(data).then(() => {
          setAddParticipantresponse(data);
        });
        window.localStorage.removeItem(ADD_PARTICIPANT_BODY);
        window.localStorage.removeItem(BOOKING_ID);
        setIsBookingComplete(true);
      } else {
        throw new Error();
      }
    }
  };

  useEffect(() => {
    if (id) {
      (async () => {
        await fetchPaymentStatus();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (isLoading) {
    return (
      <div className="ticket-view-loading">
        <Flex direction="column" gap={20} align="center" justify="center">
          <Loader size={40} />
          <Text align="center" fz="sm">
            {loadingText}
          </Text>
        </Flex>
      </div>
    );
  } else {
    return (
      <Navigate
        to={`/order-confirmation/${reduxState.eventData.className
          .replace(/\s+/g, "-")
          .toLowerCase()}/${reduxState.eventData.referenceId}/${addParticipantresponse.bookingInfo.bookingId
          }`}
        replace={true}
      />
    );
  }
}
