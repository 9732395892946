import React from "react";

function ArrowRightIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m9 18 6-6-6-6"></path>
    </svg>
  );
}

export default ArrowRightIcon;
