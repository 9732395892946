import React from "react";

const ConfirmAlert = ({
  onClose,
  message,
  title,
  onConfirm,
  onDeny,
  denyText = "No",
  confirmText = "Yes",
}) => {
  return (
    <div className="error-alert">
      <h1 className="title">{title}</h1>
      <p className="description">{message}</p>
      <div className="action-wrapper">
        <button
          className="action-deny"
          onClick={() => {
            onClose();
            onDeny();
          }}
        >
          {denyText}
        </button>
        <button
          className="action"
          onClick={() => {
            onClose();
            onConfirm();
          }}
        >
          {confirmText}
        </button>
      </div>
    </div>
  );
};

export default ConfirmAlert;
