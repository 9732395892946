import React from "react";

function CheckIcon(props) {
  return (
    <svg
      width="100%"
      height="100%"
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.544 7.255a.6.6 0 0 1 0 .85l-8.4 8.4a.6.6 0 0 1-.85 0l-4.2-4.2a.6.6 0 0 1 .85-.85l3.776 3.777 7.975-7.977a.6.6 0 0 1 .85 0Z"></path>
    </svg>
  );
}

export default CheckIcon;
