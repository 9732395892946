import React, { memo } from "react";
import { Flex, Image, Text, rem } from "@mantine/core";

import StarFilled from "../assets/icon/Star-Filled.png";
import StarHalfFilled from "../assets/icon/Star-Half-Filled.png";
import StarOutlined from "../assets/icon/Star-Outlined.png";

const RatingBar = ({ rating, showRating = false }) => {
  const maxRating = [1, 2, 3, 4, 5];

  function isInt(n) {
    return Number(n) === n && n % 1 === 0;
  }

  function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  return (
    <Flex direction="row">
      {maxRating.map((value, idx) => {
        const isRatingInt = isInt(rating);
        const isRatingFloat = isFloat(rating);

        if (isRatingInt) {
          return (
            <Image
              key={idx}
              src={
                value <= rating
                  ? StarFilled
                  : StarOutlined
              }
              alt="Alt Text"
              width={rem(16)}
              height={rem(16)}
              mr={1}
            />
          );
        } else if (isRatingFloat) {
          const nearestVal = rating - value;
          return (
            <Image
              key={idx}
              src={
                nearestVal === 0.5
                  ? StarHalfFilled
                  : value <= rating
                  ? StarFilled
                  : StarOutlined
              }
              alt="Alt Text"
              width={rem(16)}
              height={rem(16)}
              mr={1}
            />
          );
        }
      })}
      {showRating && (
        <Flex mx={2} bg="#FF5733" style={{ borderRadius: 6 }} px={6} justify="center" align="center">
          <Text color="white" fz="xs">{rating}</Text>
        </Flex>
      )}
    </Flex>
  );
};

export default memo(RatingBar);
