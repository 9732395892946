import { Flex, Text } from "@mantine/core";

export default function EventBuyingSummary({ reduxstate }) {
  const selectedEventPricing = reduxstate.selectedEventPricing.filter(
    (ticket) => ticket.bookingQty && ticket.bookingQty > 0
  );
  const addonsPricingList = reduxstate.addonsPricingList.filter(
    (addon) => "bookingQty" in addon && addon.bookingQty > 0
  );

  return (
    <div className="section">
      <div className="block">
        <Flex direction="row" justify="center" align="center">
          <Text c="#f64e60" fz="lg" fw={700}>
            Order Details
          </Text>
        </Flex>
        <Flex direction="column" gap="xs">
          {selectedEventPricing.map((ticket, index) => (
            <Flex
              key={index}
              direction="row"
              justify="space-between"
              align="center"
            >
              <Flex direction="column">
                <Text fw={700}>{ticket.ticketType}</Text>
                <Text fz="xs" c="gray">
                  {ticket.bookingQty} X €{ticket.amount}
                </Text>
              </Flex>
              <Flex direction="column" align="flex-end">
                <Text fw={700}>€{Number(ticket.totalPrice).toFixed(2)}</Text>
              </Flex>
            </Flex>
          ))}
          {addonsPricingList.map((addon, index) => (
            <Flex
              key={index}
              direction="row"
              justify="space-between"
              align="center"
            >
              <Flex direction="column">
                <Text fw={700}>{addon.addonName}</Text>
                <Text fz="xs" c="gray">
                  {addon.bookingQty} X €
                  {Number(addon.amountForTicket) +
                    (addon.serviceFee > 0 ? addon.serviceFee : 0)}
                </Text>
              </Flex>
              <Flex direction="column" align="flex-end">
                <Text fw={700}>€{Number(addon.totalPrice).toFixed(2)}</Text>
              </Flex>
            </Flex>
          ))}
          <Flex direction="row" justify="space-between" align="center">
            <Flex direction="column">
              <Text fw={700}>Processing Fee</Text>
              <Text fz="xs" c="gray">
                {reduxstate.processingFeeText.replace(/\+\s*\+/g, "+")}
              </Text>
            </Flex>
            <Text fw={700}>€{reduxstate.processingFee}</Text>
          </Flex>

          <Flex
            direction="row"
            justify="space-between"
            align="center"
            bg="#F4F4F4"
            p={12}
            style={{ borderRadius: 10 }}
          >
            <Text fz="lg">Total (inc. VAT)</Text>
            <Text fw={500} fz="lg">
              €
              {Number.isInteger(reduxstate.totalPrice)
                ? Number(reduxstate.totalPrice)
                : Number(reduxstate.totalPrice).toFixed(2)}
            </Text>
          </Flex>
        </Flex>
      </div>
    </div>
  );
}
